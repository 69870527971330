<template>
  <div :class="type == 'addEditAddress' ? 'addEditMapAddress' : ''">
    <div>
      <label style="width: 100%">
        <!-- v-model="formattedAddress" -->
        <gmap-autocomplete
          v-if="editable"
          :placeholder="$t('Search')"
          :options="{ fields: ['geometry'] }"
          class="form-control"
          @place_changed="setPlace"
          style="
            border: 1px solid #888;
            padding: 10px;
            width: 100%;
            border: 1px solid #a53860;
          "
        >
        </gmap-autocomplete>
      </label>
      <br  v-if="editable" />
    </div>
    <br  v-if="editable" />
    <!-- :map-type-id="mapTypeId" -->
    <GmapMap
      ref="mymap"
      class="mx-auto"
      :center="center"
      :zoom="18"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
      }"
    >
      <!-- :position="marker"
        :clickable="true"
        :draggable="true" -->
      <gmap-marker
        :position="marker"
        :clickable="editable == true ? true : false"
        :draggable="editable == true ? true : false"
        @click="center = marker.position"
        @dragend="updateCoordinates"
      />
      <!-- @drag="updateCoordinates" -->
    </GmapMap>
    <!-- @click="toggleInfo(item, key)" -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["editCoordinates", "type", "editable"],
  data() {
    return {
      center: { lat: 23.885942, lng: 45.079162 },
      mapTypeId: "terrain",
      marker: { lat: 23.885942, lng: 45.079162 },
      formattedAddress: "",
    };
  },
  watch: {
    editCoordinates() {
      this.geolocate();
    },
  },
  methods: {
    geolocate() {
      if (this.editCoordinates.lat && this.editCoordinates.lng) {
        this.center = {
          lat: this.editCoordinates.lat,
          lng: this.editCoordinates.lng,
        };
        this.marker = {
          lat: this.editCoordinates.lat,
          lng: this.editCoordinates.lng,
        };
        // this.getFormattedAddress();
      } else {
        this.center = {
          lat: 24.7136,
          lng: 46.6753,
        };
        this.marker = {
          lat: 24.7136,
          lng: 46.6753,
        };
        this.sendLatLngToParent();
      }
    },
    getCurrentPosition() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.marker = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        // this.getFormattedAddress();
        this.sendLatLngToParent();
      });
    },
    setPlace(place) {
      this.center.lat = place.geometry.location.lat();
      this.center.lng = place.geometry.location.lng();
      this.marker.lat = place.geometry.location.lat();
      this.marker.lng = place.geometry.location.lng();
      this.sendLatLngToParent();
    },
    updateCoordinates(location) {
      this.center.lat = location.latLng.lat();
      this.center.lng = location.latLng.lng();
      this.sendLatLngToParent();
      // this.getFormattedAddress();
    },
    getFormattedAddress() {
      axios
        .get(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            this.center.lat +
            "," +
            this.center.lng +
            "&key=AIzaSyDfpn-3DVmT9xAsbQ-kWN4Rl4oeGO5E4wk"
        )
        .then((response) => {
          if (response.data.status == "OK") {
            console.log(response.data);
            if (response.data.results[1] !== undefined) {
              $(".pac-target-input").val(
                response.data.results[1].formatted_address
              );
              // this.$emit(
              //   "getFormattedAddress",
              //   response.data.results[1].formatted_address
              // );
              // this.formattedAddress =
              //   response.data.results[1].formatted_address;
            }
          }
        });
    },
    sendLatLngToParent() {
      this.$emit("updateLatLng", this.center.lat, this.center.lng);
    },
    UpdateLatLngFromParent() {
      // console.log("called from parent");
      this.center.lat = parseFloat(this.editCoordinates.lat);
      this.center.lng = parseFloat(this.editCoordinates.lng);
      this.marker.lat = parseFloat(this.editCoordinates.lat);
      this.marker.lng = parseFloat(this.editCoordinates.lng);
    },
  },
  mounted() {
    this.geolocate();
    setTimeout(() => {
      console.log($(".pac-target-input"));
      $(".pac-target-input").attr("placeholder", "بحث");
    }, 200);
  },
};
</script>

<style lang="scss" scoped>
.vue-map-container {
  height: 450px;
  max-width: 940px;
  width: 500px;
}
.addEditMapAddress .vue-map-container {
  height: 300px;
  max-width: 100%;
  width: 420px;
}

@media (max-width: 600px) {
  .vue-map-container {
    height: 230px;
    max-width: 230px;
    width: 230px;
  }
}
</style>
