import axios from "axios";
// import { required, maxLength, email } from "vuelidate/lib/validators";

export const MasterDataMixin = {
  data() {
    return {
      showMapLocation: false,
      validationRules: {
        required: (v) => !!v || this.$i18n.t("This field is required"),
        required_multiple: (v) =>
          !v.length == 0 || this.$i18n.t("This field is required"),
        year: (v) => {
          if (v) {
            return (
              v.length == 2 ||
              v.length == 4 ||
              this.$i18n.t("should be a two-digit or four-digit year")
            );
          } else return true;
        },
        month: (v) => {
          if (v) {
            return v <= 12 || this.$i18n.t("Please enter valid month");
          } else return true;
        },
        positiveFloatNumber: (v) => {
          if (v) {
            return (
              (!isNaN(v) && Number(v) > 0 && /^\d+(\.\d{1,2})?$/.test(v)) ||
              this.$i18n.t("Please enter valid number")
            );
          } else return true;
        },
        positiveFloatNumberNozro: (v) => {
          if (v) {
            return (
              (!isNaN(v) && Number(v) > 0) ||
              this.$i18n.t("Please enter valid number")
            );
          } else return true;
        },
        // imageValidation: v =>
        //   v.size < 2000000 || "Avatar size should be less than 2 MB!",
        // minOne: v => v >= 1 || this.$i18n.t("minOneValidation"),
        maxLength2: (v) => {
          if (v) {
            return (
              v.length <= 2 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          } else return true;
        },
        maxLength4: (v) => {
          if (v) {
            return (
              v.length <= 4 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          } else return true;
        },
        maxLength5: (v) => {
          if (v) {
            return (
              v.length <= 5 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          } else return true;
        },
        maxLength10: (v) => {
          if (v) {
            return (
              v.toString().length <= 10 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          } else return true;
        },
        maxLength13: (v) => {
          if (v) {
            return (
              v.length <= 13 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          } else return true;
        },

        maxLength50: (v) => {
          if (v) {
            return (
              v.length <= 50 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          } else return true;
        },
        maxLength100: (v) => {
          if (v) {
            return (
              v.length <= 100 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          } else return true;
        },
        maxLength150: (v) => {
          if (v) {
            return (
              v.length <= 150 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          } else return true;
        },
        maxLength400: (v) => {
          if (v) {
            return (
              v.length <= 400 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          } else return true;
        },
        maxLength1000: (v) => {
          if (v) {
            return (
              v.length <= 1000 ||
              this.$i18n.t("you have reached max allowed characters")
            );
          } else return true;
        },
        maxLength25: (v) => {
          if (v)
            return (
              v.length <= 25 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          else return true;
        },
        maxLength20: (v) => {
          if (v)
            return (
              v.length <= 20 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          else return true;
        },
        maxLength225: (v) => {
          if (v)
            return (
              v.length <= 225 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          else return true;
        },
        maxLength6: (v) => {
          if (v)
            return (
              (v && v.length <= 6) ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          else return true;
        },
        floatNumber: (v) => {
          if (v)
            return (
              (v && /^\d*\.?(?:\d{1,2})?$/.test(v)) ||
              this.$i18n.t("This field only accept positive numbers")
            );
          else return true;
        },
        floatNumberNotZero: (v) => {
          if (v)
            return (
              (v && /^\d*\.?(?:\d{1,2})?$/.test(v) && v > 0) ||
              this.$i18n.t("This field only accept positive numbers")
            );
          else return true;
        },
        numeric: (v) => {
          if (v)
            return (
              (v && /^\d+$/.test(v)) ||
              this.$i18n.t("This field only accept positive numbers")
            );
          else return true;
        },
        numericNotZero: (v) => {
          if (v)
            return (
              (v && /^\d+$/.test(v) && v > 0) ||
              this.$i18n.t("This field only accept numbers large than zero")
            );
          else return true;
        },
        percentage: (v) =>
          (v > 0 && v <= 100) ||
          this.$i18n.t("The percentage must be between 0 and 100."),
        alphaNum: (v) =>
          (v && /^\w+$/.test(v)) || this.$i18n.t("Please enter a valid format"),
        // /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,10}$/.test(v)
        email: (v) =>
          /.+@.+\..+/.test(v) || this.$i18n.t("Please enter a valid format"),
        phoneNum: (v) =>
          /^(\+?)[0-9]+$/.test(v) ||
          this.$i18n.t("Please enter a valid format"),
        notStartWithZero: (v) =>
          (v.length == 9 && v[0] != 0) ||
          this.$i18n.t(
            "This field must be only 9 numbers and not start with 0"
          ),
        spaces: (v) =>
          /^(.*[^ ].*)/.test(v) ||
          this.$i18n.t("This field shouldn't contain spaces only"),
        maxLength255: function (v) {
          if (v)
            return (
              v.length <= 255 ||
              this.$i18n.t(
                "You have reached your maximum limit of characters allowed"
              )
            );
          else return true;
        },
      },

      numeric: (v) =>
        (v && /^(\+?)[0-9]+$/.test(v)) ||
        this.$i18n.t("This field only accept numbers"),
      alphaNum: (v) =>
        (v && /^\w+$/.test(v)) || this.$i18n.t("Please enter a valid format"),
      email: (v) =>
        /.+@.+\..+/.test(v) || this.$i18n.t("Please enter a valid format"),
      phoneNum: (v) =>
        /^(\+?)[0-9]+$/.test(v) || this.$i18n.t("Please enter a valid format"),
    };
  },
  methods: {
    getData(submit = false) {
      this.loading = true;
      // to disable btn untile return from backend
      let id = "";
      if (localStorage.school != "null") {
        id = "/" + localStorage.school;
      }
      if (submit == true) {
        this.submitted = true;
        axios
          .post(
            this.getApiUrl + "/school/registerSchoolMasterData" + id,
            this.masterDataEdit,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            // activate submit btn again
            this.loading = false;
            this.submitted = false;
            // this.$v.$reset();
            // console.log("mixins");
            // when submit successfully
            if (!response.data.status.error) {
              localStorage.school = response.data.data.id;
              if (this.currentAppLocale == "ar") {
                this.successMsg = "تم الحفظ بنجاح";
              } else {
                this.successMsg = "Data saved successfully";
              }

              axios
                .get(this.getApiUrl + "/school/showSchoolMasterData" + id, {
                  headers: {
                    Authorization: "Bearer " + localStorage.token,
                    //the token is a variable which holds the token
                  },
                })
                .then((response) => {
                  this.masterData = response.data.data;
                  // this.masterDataEdit = response.data.data;
                  this.editable = false;
                  if (this.masterData && this.masterData.en) {
                    localStorage.logo = this.masterData.logo;
                    localStorage.school_name = this.masterData.en.name;
                  }
                  location.reload();
                  // this.validation_errors = [];
                });
              // if success route to dashboard
              // this.$router.push("/MasterData");
            } else {
              this.validation_errors = response.data.status.validation_errors;
              this.error = response.data.status.error;
            }
          });
        axios
          .get(this.getApiUrl + "/school/editSchoolMasterData" + id, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          })
          .then((response) => {
            this.loading = false;
            if (response.data.data.authourization_number !== undefined)
              this.masterDataEdit = response.data.data;
          });
      }
      if (submit == false) {
        if (localStorage.school != "null") {
          axios
            .get(this.getApiUrl + "/school/showSchoolMasterData" + id, {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            })
            .then((response) => {
              this.loading = false;
              if (response.data.data.authourization_number !== undefined)
                this.masterData = response.data.data;
            });

          // edit api
          axios
            .get(this.getApiUrl + "/school/editSchoolMasterData" + id, {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            })
            .then((response) => {
              this.loading = false;
              if (response.data.data.authourization_number !== undefined)
                this.masterDataEdit = response.data.data;
              if (!this.masterDataEdit.location_lat) {
                this.showMapLocation = false;
              } else {
                this.showMapLocation = true;
              }
            });
        }
      }
    },

    cancel() {
      // edit api
      let id = "/" + localStorage.school;
      this.editable = false;

      if (localStorage.school != "null") {
        axios
          .get(this.getApiUrl + "/school/editSchoolMasterData" + id, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          })
          .then((response) => {
            if (response.data.data.authourization_number !== undefined)
              this.masterDataEdit = response.data.data;
          });
      }
      axios
        .get(this.getApiUrl + "/school/showSchoolMasterData" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          if (response.data.data.authourization_number !== undefined)
            this.masterData = response.data.data;
          // this.masterDataEdit = response.data.data;
        });
      this.validation_errors = [];
    },
  },
};
